import * as React from 'react';
import { Helmet } from 'react-helmet';

import Layout from '../components/Layout';

const CookiePolicyPage = (): JSX.Element => {
    return (
        <>
            <Helmet title="Cookie Policy" />
            <Layout>
                <div className="page">
                    <h1 className="page__title">Cookie Policy</h1>
                    <div className="page__cnt">
                        <p>This website uses cookies. Cookies tell us which parts of our websites people have visited, help us measure the effectiveness of ads and web searches, and give us insights into user behaviour so we can improve our communications and products. We use the following categories on our websites and other online services:</p>
                        <p>
                            <b>Category 1 - Strictly Necessary Cookies</b>
                            <br />
                            These cookies are essential to enable you to browse our websites.
                        </p>
                        <p>
                            <b>Category 2 - Performance Cookies</b>
                            <br />
                            These cookies collect information about how you use our website — for example, which pages you go to most. This data may be used to help optimise our website and make it easier for you to navigate. These cookies don’t collect information that identifies you. All information these cookies collect is aggregated and therefore anonymous.
                        </p>
                        <p>
                            <b>Category 3 - Functionality Cookies</b>
                            <br />
                            These cookies allow our website to remember choices you make while browsing. They may also be used to keep track of what you’ve viewed to avoid repetition. The information these cookies collect will not personally identify you, and they cannot track your browsing activity on any other websites.
                        </p>
                    </div>
                </div>
            </Layout>
        </>
    );
};

export default CookiePolicyPage;
